import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Map from '../../../components/Map'
import { useStyles } from './styles'

interface Props {
  device: DeviceData
}

const Location: React.FunctionComponent<Props> = ({ device }) => {
  const classes = useStyles()
  const { latitude: lat, longitude: lng } = device

  const addMarker = () => (map: google.maps.Map) => {
    return new window.google.maps.Marker({ position: { lat, lng }, map })
  }

  const mapProps = {
    options: {
      center: { lat, lng },
      zoom: 12
    },
    onMount: addMarker()
  }

  return (
    <Grid container direction="column" style={{ height: '100%' }}>
      <Paper square className={classes.paper}>
        <Typography variant="h6" className={classes.paperHeader}>
          <Box
            component="p"
            m={0}
            fontSize="h6.fontSize"
            fontWeight="fontWeightLight"
          >
            LOCATION
          </Box>
        </Typography>
        {lat && lng ? (
          <Map {...mapProps} />
        ) : (
          <Typography variant="h5" className={classes.noLocationMessage}>
            No location information are available
          </Typography>
        )}
      </Paper>
    </Grid>
  )
}

export default Location
