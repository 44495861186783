import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'

import Container from '@material-ui/core/Container'

import { MqttClient } from 'mqtt'

import { mqttService } from '../../../actions'
import MessagesPanel from './MessagesPanel'
import PublishPanel from './PublishPanel'
import { useStyles } from './styles'
import Error from '../../../components/Error'
import Spinner from '../../../components/Spinner'

const Main: React.FC<{ device: DeviceData }> = ({ device }) => {
  const id = device.id
  const classes = useStyles()
  const [mqttClient, setMqttClient] = useState<MqttClient | null>(null)
  const [connected, setConnected] = useState<boolean>(false)
  const [error, setError] = useState<string>('')
  const [messages, setMessages] = useState<MqttMessage[]>([])

  useEffect(() => {
    const client = mqttService.getClient()
    setMqttClient(client)

    client.on('connect', () => {
      mqttService.subscribeToAll(client, id as string)
      mqttService.subscribeToQueclinkIncoming(
        client,
        String(device.properties.IMEI)
      )
      setConnected(true)
    })

    client.on('error', (err: Error) => setError(err.message))

    const handleMessage = (channel: string, message: string) => {
      const timestamp = new Date()
      setMessages(prev => [
        ...prev,
        {
          channel,
          message,
          timestamp
        }
      ])
    }

    mqttService.onMessage(client, handleMessage)

    return () => {
      setConnected(false)
      mqttService.closeConnection(client, id as string)
      setMqttClient(null)
    }
  }, [])

  const onPublish = (message: string) => {
    if (mqttClient) {
      mqttService.publish(
        mqttClient,
        `queclink/${device.properties.IMEI}/outgoing`,
        message.trim()
      )
    }
  }

  const onSendSMS = (message: string) => {
    if (mqttClient) {
      mqttService.publish(
        mqttClient,
        `queclink/${device.properties.IMEI}/outgoing/sms`,
        message.trim()
      )
    }
  }

  if (!connected && error) {
    return <Error legend={error} />
  }

  return (
    messages && (
      <Grid container spacing={3} className={classes.grid}>
        <Grid item xs={12}>
          <PublishPanel onPublish={onPublish} onSendSMS={onSendSMS} />
        </Grid>

        <Grid item xs={12}>
          <MessagesPanel messages={messages} connected={connected} />
        </Grid>
      </Grid>
    )
  )
}

const DeviceMessages: React.FC<{
  device: DeviceData | null
  loading: boolean
}> = ({ device, loading }) => {
  const classes = useStyles()

  if (loading || !device) {
    return <Spinner legend="Loading device..." />
  }

  return (
    <Container>
      <Grid container spacing={3} className={classes.grid}>
        <Main device={device} />
      </Grid>
    </Container>
  )
}

export default DeviceMessages
