import React, { useEffect, useRef } from 'react'
import './styles.scss'

interface Props {
  options: Record<string, any>
  onMount: (map: google.maps.Map) => void
}

const Map: React.FunctionComponent<Props> = ({ options, onMount }) => {
  const mapEl = useRef<HTMLInputElement>(null)

  const onLoad = () => {
    if (mapEl.current) {
      const map = new window.google.maps.Map(mapEl.current, options)

      if (onMount) {
        onMount(map)
      }
    }
  }

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement(`script`)
      script.type = `text/javascript`
      script.src = `https://maps.google.com/maps/api/js?key=${process.env.GOOGLE_MAPS_API_KEY}`
      document.head.appendChild(script)
      script.addEventListener(`load`, onLoad)
      return () => script.removeEventListener(`load`, onLoad)
    } else {
      onLoad()
    }
  })

  return <div ref={mapEl} className="Map" />
}

export default Map
